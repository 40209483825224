@tailwind base;
@tailwind components;
@tailwind utilities;
@import-normalize;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* @import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css"; */

@import url(https://fonts.googleapis.com/css?family=Montserrat);

/* *, html, body {
    font-family: Montserrat;
} */
