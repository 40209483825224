.inActiveBar {
    position: fixed;
    z-index: 100;
    opacity: 0;
    animation: slideDown 1s ease 0.1s;
    animation-fill-mode: forwards;
}

@keyframes slideDown {
    0% {
        visibility: visible;
        opacity: 0;
        top: -100%;
    }
    100% {
        visibility: visible;
        opacity: 1;
        top: 0;
    }
}