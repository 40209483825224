.department-animation {
    opacity: 0;
    animation: department-appear 2s ease 0.5s;
    animation-fill-mode: forwards;
}

@keyframes department-appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}